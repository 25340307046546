<template>
  <div>
    <a-modal
      title="开发票"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-form :form="form" :selfUpdate="true">
          <a-row :gutter="24">
            <a-col :md="24" :sm="24">
              <a-form-item label="上传发票" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-upload
                  style="margin: auto"
                  name="avatar"
                  accept=".png,.jpg,jpeg,.gif,.pdf"
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="uploadImg"
                  v-decorator="['bill', { rules: [{ required: true, message: '请上传发票！' }] }]"
                >
                  <img
                    src="@/assets/success.png"
                    v-if="picture && picture.includes('.pdf')"
                    style="width: 200px; height: 100px"
                  />
                  <img :src="picture" v-else-if="picture" style="width: 200px; height: 100px" />
                  <div v-else>
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { uploadBill } from '@/api/modular/mallLiving/recharge'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 4 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 20 },
        sm: { span: 20 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading: false,
      id: '',
      picture: '',
      cos: null,
    }
  },

  methods: {
    handlerIntegralChange(event) {
      this.$nextTick(() => {
        console.log(event)
        this.form.setFieldsValue({ price: event.target.value })
      })
    },
    // 初始化方法
    add(record) {
      //下面是正常的初始化
      this.id = record.id
      this.visible = true
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          uploadBill({ ...values, id: this.id }).then((res) => {
            if (res.success) {
              this.$message.success('开发票成功！')
              this.$emit('ok', values)

              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.picture = ''
      this.form.resetFields() //重置表单
    },
    //自定义图片上传实现
    uploadImg(filedata) {
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            this.picture = list[0]
            this.form.setFieldsValue({ bill: this.picture })
            
            this.pictureLoading = false
          }
        }
      })
    },
  },
}
</script>
<style scoped>
/deep/ .ant-input-disabled {
  background: #fff;
}
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
